import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import ProgressBar from 'react-bootstrap/ProgressBar';
import "../Css/play.css";
import { sendQuestion,submitScore } from "../Api/api";
import { quizData } from "../Data/data";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const Play = () => {
  // eslint-disable-next-line
const { t } = useTranslation();

  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

useEffect(()=>{
  handleChangeLang();
},[]);

    const navigate=useNavigate();

    //state to hold user data
    const[user,setUser]=useState({});

    //state to hold color of progress bar
    const[variantValue,setVariantValue]=useState('success');

    //State to hold score of user
    const[userScore,setUserScore]=useState(0);

    //Hook to call getLocalStorageData Method
    useEffect(()=>{
        getToken();
        disablingOptions();
        getLocalStorageData();
        // eslint-disable-next-line
    },[]);

    //state to hold quiz data i.e question & answer
    const[quiz,setQuiz]=useState({});

    const[data,setData]=useState({});

    //Getting User Data from LocalStorage
    const getLocalStorageData=()=>{
        let user=JSON.parse(localStorage.getItem("user"));
        //console.log("user is ",user);
        setUser(user);

        //  let game=localStorage.getItem("game");
         let category=localStorage.getItem("category");
         let ani=localStorage.getItem("ani");
         let language=localStorage.getItem("language");
         let service=localStorage.getItem("service");

        setData({...data,category:category,ani:ani,language:language,service:service});

        //console.log("game: ",game);
        //console.log("category: ",category);
        //console.log("ani: ",ani);
        //console.log("language: ",language);
        //console.log("service: ",service);

        gettingQuizData(ani,category,language,service);
    }

    //Method to hit on backend & fetch quiz data
    const gettingQuizData=(ani,category,language,service)=>{
      let token=localStorage.getItem("token");
      let headers={"Authorization":"Bearer "+token};

      let request={"ani":ani,"category":category,"language":language,"service":service};
      //console.log("request is ",request);
      axios.post(`${sendQuestion}`,request,{headers:headers})
      .then(
        (response)=>{
          //console.log("response is : ",response.data.quiz);
          if(response.data.quiz===null)
          {
            setQuiz(quizData.quiz);
          }
          else
          {
            setQuiz(response.data.quiz);
            document.getElementById("optionAButton").style.background="white";
            document.getElementById("optionBButton").style.background="white";
            enablingOptions();
            startHandler();
          }
        },
        (error)=>{
          //console.log(error);
          if(error.response.status===403)
          {
            //console.log("Token Expired");
            toast.error("Token Expired Plz Login again !!");
            navigate("/");
          }
          else
          {
            toast.error("Something Went Wrong");
          }
        }
      )
    }

    //Checking answers
    const handleAnswer=(answer,id)=>{
      //console.log("answer is ",answer);
      //console.log("correct answer is ",quiz.correctAnswer);
      stopHandler();
      disablingOptions();

      let secondId="";
      if(id==="optionAButton")
      {
        secondId="optionBButton";
      }
      else if(id==="optionBButton")
      {
        secondId="optionAButton";
      }

      if(answer===quiz.correctAnswer)
      {
        let score=18;
        // //console.log("add score is ",score);

        // //console.log("user score is ",userScore);
        score=score+userScore;
        // //console.log("new score is ",score);

        setUserScore(score);

        //console.log("Correct Answer");
        toast.success(t("Correct Answer"));
        //Fetch Next Question
        // //console.log("ani ",data.ani);
        // //console.log("category ",data.category);
        // //console.log("language ",data.language);
        // //console.log("service ",data.service);

        document.getElementById(id).style.background="green";
        document.getElementById(secondId).style.background="red";

        gettingQuizData(data.ani,data.category,data.language,data.service);

      }
      else
      {
        //console.log("Wrong Answer");
        toast.error(t("Wrong Answer"));

        document.getElementById(id).style.background="red";
        document.getElementById(secondId).style.background="green";

        //console.log("Final Score is ",userScore);
        localStorage.setItem("userScore",userScore);
        
        //Submitting Score
        submittingUserScore(userScore);

        setTimeout(()=>{
          navigate("/score");
        },2000);
      }
    }

    const submittingUserScore=async(score)=>{
      let category=localStorage.getItem("category");
      let ani=localStorage.getItem("ani");
      let language=localStorage.getItem("language");
      let service=localStorage.getItem("service");

      let token=localStorage.getItem("token");
      let headers={"Authorization":"Bearer "+token};

      let request={"category":category,"ani":ani,"language":language,"service":service,"score":score};
      await axios.post(`${submitScore}`,request,{headers:headers})
      .then(
        (response)=>{
          //console.log(response.data.response);
          localStorage.setItem("userTotalScore",response.data.response);
        },
        (error)=>{
          //console.log(error);
          if(error.response.status===403)
          {
            //console.log("Token Expired");
            toast.error("Token Expired Plz Login again !!");
            navigate("/");
          }
          else
          {
            toast.error("Something Went Wrong");
          }
        }
      )
    }

  //-----------------------------------------State Time Stuff---------------------------//

  const START_SECOND ='20';
  const START_DERATION =20;

  const [currentSeconds, setSeconds] = useState(START_SECOND);
  // eslint-disable-next-line
  const [isStop, setIsStop] = useState(false); 
  const [isRunning, setIsRunning] = useState(false);
  const [duration, setDuration] = useState(START_DERATION);

  const startHandler = () => {
      // //console.log("Start Timer");
      // //console.log(parseInt(START_SECOND, 10));
      setDuration(parseInt(START_SECOND, 10));
      setIsRunning(true);
    };

    const stopHandler = () => {
      // stop timer
      // //console.log("Stoping Timer");
      setIsStop(true);
      setIsRunning(false);
    };

  //   const resetHandler = () => {
  //     setSeconds(START_SECOND);
  //     setIsRunning(false);
  //     setIsStop(false);
  //     setDuration(START_DERATION);
  // };

    useEffect(() => {
      if (isRunning === true) {
        let timer = duration;
        // let gap=40;
        // let repeat=0;
        let seconds;
        const interval = setInterval(function () {
          if (--timer <= 0) {
            // resetHandler();
            //console.log("Time Over");
            toast.error("Times Up");
            navigate("/home");

        } else {
          seconds = parseInt(timer % 60, 10);
          seconds = seconds < 10 ? parseInt('0'+seconds) : seconds;    
          setSeconds(parseInt(seconds*5));
          // //console.log("seconds ",seconds);

          if(parseInt(seconds)===13)
          {
            setVariantValue("warning");
          }
          else if(parseInt(seconds)===5)
          {
            setVariantValue("danger");
          }

          // if(seconds===1)
          // {
          //   //console.log("Inside check");
          //   repeat =repeat+1;
          //   if(repeat===1)
          //   {
          //     timer=40;
          //     gap=0;  
          //   }
          // }
        }
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [isRunning]);

  //-----------------------------------------State Time Stuff---------------------------//

  const disablingOptions=()=>{
    //Disabling Options
     setTimeout(()=>{
      // document.getElementById("optionA").style.pointerEvents = "none";
      // document.getElementById("optionB").style.pointerEvents = "none";
     },1000);
  }

  const enablingOptions=()=>{
    //Enabling Options
    //console.log("Inside Enabling Options");
    document.getElementById("optionA").style.pointerEvents = "visible";
    document.getElementById("optionB").style.pointerEvents = "visible";
  }

  //Method to Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

  if(token===undefined||token===null||token===''||token===' ')
  {
	return(
      <>
        <SelectLanguage/>
      </>
    );
  }
  else
  {
    return (
      <>
        <ToastContainer/>
        <section className="play-main full-height">
          <div className="play-main-2 flex-grow-1">
            <div className="rewards-navbar ">
              <div className="rewards-nav-1 play_head">
                <button 
                  onClick={()=>{
                    navigate("/home");
                  }}>
                  <span>
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                </button>
                <div className="nav-prfl">
                  <div className="play-prfl-img">
                    <img alt="ImgSrc" src={user.image} />
                  </div>
                  <div className="play-prfl-data">
                    <h5>{user.name}</h5>
                    <p>0</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="play-nav">
              <div className="nav-prfl">
                <div className="play-prfl-img">
                  <img alt="ImgSrc" src={user.image} />
                </div>
                <div className="play-prfl-data">
                  <h5>{user.name}</h5>
                  <p>0</p>
                </div>
              </div>
              <button onClick={()=>{
                  navigate("/home");
                }}>
                  <span className="showscore-span-1">
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                  <span className="showscore-span-2">{t('Back to Topic')}</span>
                </button>
            </div> */}
            <div className="play-full-body">
              <div className="play-ques-1 not-select">
                <p>{quiz.question}</p>
              </div>
  
              <div id="play-progressbar">
                <div className="play-watch">
                  <i className="fa-solid fa-2x fa-stopwatch"></i>
                </div>
                {/* <div className="my-progressbar"></div> */}
                <ProgressBar now={currentSeconds} animated variant={variantValue}/>
              </div>
              {/* <button onClick={()=>{
                stopHandler();
              }}>Stop Timer</button> */}
              
              {/* Time:<span id="timeDiv">{currentSeconds}</span> */}
  
              <div className="play-option">
                <div className="play-click-option option-1 not-select" id="optionA">
                  <button
                    onClick={()=>{
                      handleAnswer(quiz.optionA,"optionAButton");
                    }} id="optionAButton">{quiz.optionA}</button>
                </div>
                <div className="play-click-option option-2 not-select" id="optionB">
                  <button
                    onClick={()=>{
                      handleAnswer(quiz.optionB,"optionBButton");
                    }} id="optionBButton">{quiz.optionB}</button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    ); 
  }
};
export default Play;