import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

export const Sidebar = (props) => {

  //To Change Language
  const { t } = useTranslation();

  //Method to Get User Selected Language from Local Storage
  const handleChangeLang = () => {
    let language = localStorage.getItem("language");
    i18n.changeLanguage(language);
  };

  //To Load Language
  useEffect(() => {
    handleChangeLang();
  }, []);

  //To Navigate
  const navigate = useNavigate();

  //State to store side bar display
  // eslint-disable-next-line 
  const [display, setDisplay] = useState("none");

  //Method to Hide sidebar
  const closeSidebar = () => {
    // setDisplay("none");
      let d=document.getElementById("left-sidebar-main");
      d.classList.remove('my-show');
  };

  //useEffect to update display hook
  useEffect(() => {}, [display]);

  //to handle logout
  const handleLogout = () => {
    localStorage.clear();
    localStorage.setItem("language", "en");
    navigate("/");
  };

  const gettingScreenDimensions = () => {
    let width = window.innerWidth;
    // console.log("width is ", width);

    if (width <= 768) {
      setTimeout(() => {
        console.log("width is 768");
        // let d=document.getElementById("left-sidebar-main");
        // d.classList.add('my-show');
        document.getElementById("close-button").style.display = "block";
      }, 1000);
    }
  };

  gettingScreenDimensions();

  return (
    <>
        <div className="sidebar" id="left-sidebar-main">
            <div className="profile">
            <img alt="ImgSrc" className="img-fluid" src={props.userImage} />
            <h3>{props.userName}</h3>
            <p>{props.userAni}</p>
            <p className="cut">
                    <button
                    onClick={() => {
                        closeSidebar();
                    }}
                    style={{display:"none"}}
                    id="close-button"
                    >
                    <i className="fa-solid fa-xmark"></i>
                    </button>
                </p>
            </div>
            
            <div className="all-ul">
            <ul>
                <li>
                <button
                onClick={() => {
                    navigate("/reward");
                }}>
                    <span className="home-icon">
                    <i className="fa-solid fa-gift"></i>
                    </span>
                    <span className="home-item">{t("Rewards")}</span>{" "}
                </button>
                </li>
                <li>
                <button onClick={() => {navigate("/notification")}}>
                    <span className="icon">
                    <i className="fa-solid fa-bell"></i>
                    </span>
                    <span className="item">{t("Notification")}</span>
                </button>
                </li>
                <li>
                <button
                    onClick={() => {
                    navigate("/invite");
                    }}>
                    <span className="icon">
                    <i className="fa-solid fa-envelope-open"></i>
                    </span>
                    <span className="item">{t("Invite friends")}</span>{" "}
                </button>
                </li>
                <li>
                <button
                    onClick={() => {
                    navigate("/setting");
                    }}>
                    <span className="icon">
                    <i className="fa-solid fa-gear"></i>
                    </span>
                    <span className="item">{t("Setting")}</span>
                </button>
                </li>
                <li>
                <button
                    onClick={() => {
                    handleLogout();
                    }}>
                    <span className="icon">
                    <i className="fa-solid fa-right-from-bracket"></i>
                    </span>
                    <span className="item">{t("Log out")}</span>{" "}
                </button>
                </li>
            </ul>
            </div>
      </div>
    </>
  )
}
