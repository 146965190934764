import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer,toast } from "react-toastify";
import axios from "axios";
import "../Css/search.css";
import { sendServices } from "../Api/api";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const Search = () => {
  const { t } = useTranslation();

  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

useEffect(()=>{
  handleChangeLang();
},[]);

   //navigate to redirect on any other page
   const navigate=useNavigate();

    //Will Load on First Time
    useEffect(()=>{
        getToken();
        getServicesData();
        // eslint-disable-next-line
    },[]);

    //method will call mentioned method
    const getServicesData=()=>{
        hitOnApiBackend();
    }

    //State to hold services data
    const[services,setServices]=useState([]);

    //Method to hit on backend & get all services/categories data
    const hitOnApiBackend=()=>{

      let token=localStorage.getItem("token");
      let headers={"Authorization":"Bearer "+token};

        axios.get(`${sendServices}`,{headers:headers})
        .then(
            (response)=>{
                //console.log(response.data.response);
                setServices(response.data.response);
            },
            (error)=>{
              //console.log(error);
              if(error.response.status===403)
              {
                //console.log("Token Expired");
                toast.error("Token Expired Plz Login again !!");
                navigate("/");
              }
              else
              {
                toast.error("Something Went Wrong");
              }
            }
        )
    }

    //State to store searched values
    const[searchedResult,setSearchedResult]=useState([]);

    useEffect(()=>{
    },[searchedResult]);

    //This Method will handle search
    const handleSearch=(e)=>{
        //console.log(e.target.value);
        //console.log("services are ",services);

        let split=e.target.value.toLowerCase().split("");
        //console.log("split ",split);

        let language=localStorage.getItem("language");
        let langName;

        let searchArray=[];
        services.forEach((m=>{

          if(language==="en")
          {
            langName=m.name;
          }
          else if(language==="ps")
          {
            langName=m.pushtoName;
          }
          else if(language==="dr")
          {
            langName=m.dariName;
          }  

          // eslint-disable-next-line
            let subString=langName.toLowerCase().substring(0,split.length);
          
            //console.log("name ",langName.toLowerCase());
            //console.log("subString ",subString);

            if(e.target.value!=='' && e.target.value!==' ' && (langName.toLowerCase().startsWith(e.target.value)
                || langName.startsWith(e.target.value)))
            {
              //console.log("Matched");
              searchArray.push(m);
            }
        }))
        //console.log("searchedArray is ",searchArray);
        setSearchedResult(searchArray);

        //console.log("searchedResult is ",searchedResult);
    }

    //Method to handle searched category & send to that one
    const sendToSearched=(value)=>{
      //console.log("searched data is ",value);

      localStorage.setItem("game", JSON.stringify(value));
      setCategoryAndService(value.name, value.service);
      navigate("/game");

    }

    const setCategoryAndService = (cat, ser) => {
      localStorage.setItem("category", cat);
      localStorage.setItem("service", ser);
    }
  
//Method to Get & Check Token
const getToken=()=>{
  token=localStorage.getItem("token");
}

  if(token===undefined||token===null||token===''||token===' ')
  {
  return(
      <>
        <SelectLanguage/>
      </>
    );
  }
  else
  {
    return (
      <div>
        <ToastContainer/>
        <section className="search-main full-height">
          <div className="search-main-2 flex-grow-1">
            <div className="rewards-navbar ">
              <div className="rewards-nav-1">
                <button onClick={() => {
                    navigate("/home");
                  }}>
                  <span>
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                </button>
                <div className="reward_title">
                  <span className="rewards-para-1">
                    <button><h5>{t('Search')}</h5></button>
                  </span>
                </div>
              </div>
            </div>
            <div className="search-search-container">
              <form>
                <div className="position-relative">
                  <input type="text" placeholder={t('Search')} name="search" 
                    className="search-input"
                    onChange={(e)=>{
                        handleSearch(e);
                    }}/>
                  <button className="search_icon" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
                <div className="search-main-container">
                {searchedResult.length>0 && searchedResult.map((value,index)=>{
                  // //console.log("inside");
                  // //console.log("index ",index);
                  // //console.log("value ",value);
  
                    return(
                      <div className="search-searched-div" key={index}>
                        <button onClick={()=>{
                          sendToSearched(value);
                        }}>{t(value.name)}</button>
                      </div>
                    )
                  })}
  
                </div>
              </form>
  
              {/*Div To Show Searched Result */}
              
            </div>
  
            {/* <section className="search-footer">
              <div className="search-icon-1">
                <a href="/">
                  <i className="fa-solid fa-house-user"></i>
                  <p>Home</p>
                </a>
              </div>
              <div className="search-icon-1">
                <a href="/">
                  <i className="fa-solid fa-trophy"></i>
                  <p>League</p>
                </a>
              </div>
              <div className="search-icon-5">
                <a href="/">
                  <img alt="ImgSrc" src={unnamed_image} />
                  <p>Play</p>
                </a>
              </div>
              <div className="search-icon-1">
                <a href="/">
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <p>Search</p>
                </a>
              </div>
              <div className="search-icon-1">
                <a href="/">
                  <i className="fa-solid fa-user"></i>
                  <p>Profile</p>
                </a>
              </div>
            </section> */}
          </div>
          <Footer />
        </section>
      </div>
    ); 
  }
};
export default Search;