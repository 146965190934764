import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useFocusEffect } from '@react-navigation/native';
import azmoon_image from "../Images/Azmoon logo.png";
import "../Css/login.css";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
// import PostRequest from "../Api/PostRequest";
import { checkSubscriptionApi } from "../Api/api";
import { SyncLoader } from "react-spinners";
import {ToastContainer,toast} from "react-toastify";
import PostRequestNormal from "../Api/PostRequestNormal";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: tEn },
    ps: { translation: tPs },
    dr: { translation: tDr },
  },
  // lng:"en",
  // fallbackLng: "en",

  // interpolation: {
  //   escapeValue: false
  // }
});

const Login = () => {
  //To Translate Language
  const { t } = useTranslation();

  //To go other page
  const navigate = useNavigate();

  //Hook to store data
  const [data, setData] = useState({});

  //Method to handle login button
  const handleSubmit = () => {

    //showing loader etc
    handleLoginProcess(true);

    //Printing Data on console
    //console.log(data);
    //console.log(data.ani);

    //Checking for empty data
    if(data.ani===undefined)
    {
      //console.log("Empty Data");
      toast.error("Please Enter Mobile Number First");
      handleLoginProcess(false);
    }
    else
    {
      //Setting ani in local storage

      let ani=data.ani;
      if(ani.startsWith('0'))
      {
        ani=ani.substring(1);
      }
      else if(ani.startsWith('93'))
      {
        ani=ani.substring(2);
      }
      //console.log("ani is ","93"+ani);

      localStorage.setItem("ani","93"+ani);

      // will check ani is subscribed or not
      let promise=hittingOnBackendToCheck(ani);    
      promise.then(e=>{
        //console.log(e);
        handleReponse(e);
      })
    }
  };

  //Method to hit on backend
  const hittingOnBackendToCheck=(ani)=>{
    let request={"ani":ani};
    let promise=PostRequestNormal(checkSubscriptionApi,request);
    return promise;
  }

  //call when user click on login button & after response
  const handleLoginProcess=(value)=>{
    //Disable Button
    setDisableState(value);

    //Hiding Login Text
    setVisibleState(value);

    //Showing Loader
    setLoaderState(value);
  }

  //Method to handle Response after check subscription
  const handleReponse=(e)=>{
    handleLoginProcess(false);
    if(e.response==='5')
    {
      navigate("/error");
    }
    else if(e.response==='1')
    {
      //User Charged - can access game - but after entering OTP - so send to otp page
      navigate("/otp");
    }
    else if(e.response==='0')
    {
      //Billing Pending
      toast.error("Billing Pending");
    }
    else if(e.response==='2')
    {
      //Not a Subscriber so send to pack page
      toast.success("Not Subscribed");

      //Sending to subscribe page
      setTimeout(()=>{
        navigate("/subscribe");
      },2000);
    }
    else
    {
      //Something Went Wrong
      toast.error("Something Went Wrong");
    }
  }

  //Method to get Language
  useEffect(() => {
    handleChangeLang();
  }, []);

  //Hook to store loader state
  const[loaderState,setLoaderState]=useState(false);

  //Hook to store login button disable state
  const[disableState,setDisableState]=useState(false);

  //Hook to store login text visible state
  const[visibleState,setVisibleState]=useState(false);

  //Method to get language from local storage
  const handleChangeLang = () => {
    let language = localStorage.getItem("language");
    //console.log("getting language from localstorage is ", language);
    i18n.changeLanguage(language);
  };

  return (
    <>
    <ToastContainer/>
      <section className="selectlanguage-main">
        <div className="selectlanguage-main-2">
          <div className="login-quiz-logo">
            <img alt="ImgSrc" src={azmoon_image} />
          </div>
          {/* <div className="login-ques-pic">
            <img alt="Img Src" src={capture} />
          </div> */}
          <div className="login-text-1 pt-2">
            <div className="login-para-1 text-center">
              <h5>{t("Log In")}</h5>
              <p>{t("Welcome back! Log in to your account.")}</p>
            </div>
          </div>
          {/* <div className="login-para-2">
            <h5>{t('Phone Number')}</h5>
          </div> */}
          <div className="login-second-prt pt-2">
            <div className="login-inputs">
              <input
                type="text"
                id="otp"
                name="otp"
                placeholder={t("Enter your phone number here")}
                required
                className="enterNumber form-control"
                onChange={(e) => {
                  setData({ ...data, ani: e.target.value });
                }}
              />
            </div>
            <div className="login-anchors">
              <div className="login-ancr-1">
                <button
                  disabled={disableState}
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="login-button"
                >
                  <span hidden={visibleState}>{t("Log In")}</span>
                  <SyncLoader color="white" size="15" className="login-button-loader" loading={loaderState}/>
                </button>
              </div>
            </div>
          </div>
          <div className="login-back-arw">
            <button
              onClick={() => {
                navigate("/");
              }}
            >
              <i className="fa-solid fa-arrow-left-long"></i>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;