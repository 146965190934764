import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../Css/home.css";
import "../Css/buttons.css";
import { sendCategory, sendUser } from "../Api/api";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Sidebar } from "./Sidebar";
import { Footer } from "./Footer";
import { NavbarTop } from "./NavbarTop";
import options from "../Data/loader"; 
import SelectLanguage from "./1SelectLanguage";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: tEn },
    ps: { translation: tPs },
    dr: { translation: tDr },
  }
});

let token;
const Home = () => {
  //To Transalte Languages
  const { t } = useTranslation();

  //To get user selected language from local storage
  const handleChangeLang = () => {
    let language = localStorage.getItem("language");
    i18n.changeLanguage(language);
  };

  //For Loader
  let Loader = require('react-loader');

  //to change language on load
  useEffect(() => {
    handleChangeLang();
  }, []);

  //To Navigate
  const navigate = useNavigate();

  //Getting Category Wise Data from Backend
  const getCategoryWiseData = () => {
    hitOnApi();
  };

  //useState hooks to store data
  const [mainTopic, setMainTopic] = useState([]);
  const [topTopic, setTopTopic] = useState([]);
  const [latestTopic, setLatestTopic] = useState([]);

  //Hit on Api & get data for all categories
  const hitOnApi = () => {

    //Getting Token from Local Storage
    let token=localStorage.getItem("token");
    let headers={"Authorization":"Bearer "+token};

    axios.get(`${sendCategory}`,{headers:headers}).then(
      (response) => {
        // //console.log(response.data);

        let mainTopic = response.data.mainTopic; //Adding data to variables
        let topTopic = response.data.topTopic;
        let latestTopic = response.data.latestTopic;

        //console.log("mainTopic: ", mainTopic);
        //console.log("topTopic: ", topTopic);
        //console.log("latestTopic: ", latestTopic);

        setMainTopic(mainTopic); //Setting data in hook
        setTopTopic(topTopic);
        setLatestTopic(latestTopic);
        
        setLoaderState(true); //Disabling Loader

        //Setting topTopic & latestTopic in local storage
        localStorage.setItem("topTopic", JSON.stringify(topTopic));
        localStorage.setItem("latestTopic", JSON.stringify(latestTopic));

        if (
          mainTopic.length === 0 ||
          topTopic.length === 0 ||
          latestTopic.length === 0
        ) {
          toast.error("No Data Found"); //Printing if in any array there is no data
        }
      },
      (error) => {
        //console.log(error);
        if(error.response.status===403)
        {
          //console.log("Token Expired");
          toast.error("Token Expired Plz Login again !!");
          navigate("/");
        }
        else
        {
          toast.error("Something Went Wrong");
        }
      }
    );
  };

  const [userName, setUsername] = useState("");
  const [userAni, setUserAni] = useState("");
  const [userImage, setUserImage] = useState("");
  const [userFavourite, setUserFavourite] = useState({});

  //Method to get Logged in user data
  const getUserData = () => {
    // eslint-disable-next-line

    let token=localStorage.getItem("token");
    let headers={"Authorization":"Bearer "+token};

    let ani = localStorage.getItem("ani");
    let request = { ani: ani };
    axios.post(`${sendUser}`,request,{headers:headers}).then(
      (response) => {
        //console.log(response.data);
        setUsername(response.data.user.name);
        setUserAni(response.data.user.ani);
        setUserImage(response.data.user.image);
        setUserFavourite(response.data.user.favourite);
        
        //Adding User to LocalStorage
        localStorage.setItem("user", JSON.stringify(response.data.user));

        // let getUser=JSON.parse(localStorage.getItem("user"));
        // //console.log("getUser ",getUser);
      },
      (error) => {
        //console.log(error);
        if(error.response.status===403)
        {
          //console.log("Token Expired");
          toast.error("Token Expired Plz Login again !!");
          // navigate("/");
        }
        else
        {
          toast.error("Something Went Wrong");
        }
      }
    );
  };

  //Geeting Data
  useEffect(() => {
    getToken();
    getCategoryWiseData();  //To Get Categories Data
    getUserData();          //To Get User Data
    // eslint-disable-next-line
  }, []);

  //Method to handle play when click on any category
  const handlePlay = (id, data) => {
    //console.log("id is ", id);
    //Now get this id data from array i.e in hook
    data.forEach((value) => {
      if (id === value.id) {
        // //console.log("value ",value);
        localStorage.setItem("game", JSON.stringify(value));
        setCategoryAndService(value.name, value.service);
        navigate("/game");
        // let game=JSON.parse(localStorage.getItem("game"));
        // //console.log("game is ",game);
      }
    });
  };

  const setCategoryAndService = (cat, ser) => {
    localStorage.setItem("category", cat);
    localStorage.setItem("service", ser);
  };

  //Method to handle seeAll button
  const handleSeeAll = (category) => {
    //console.log("category is ", category);

    //Adding this category in localStorage
    localStorage.setItem("seeAllCategory", category);

    //navigating to seeall page
    navigate("/all");
  };

  //Hook to store loader State
  const[loaderState,setLoaderState]=useState(false);


  //Method to get token from local storage
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

  if(token===undefined||token===null||token==='')
  {
    return(
      <>
        <SelectLanguage/>
      </>
    );
  }
  else
  {
    return (
      <div>
        <ToastContainer />
  
        {/* New Sidebar Start */}
        <Sidebar userName={userName} userImage={userImage} userAni={userAni} />
        {/* Sidebar End */}
  
        <section className="home-main">
          <div className="home-main-2">
            <NavbarTop />
  
            {mainTopic.length > 0 &&
              mainTopic.map((value, index) => {
                return (
                  <div key={index}>
                    <div className="sliderBox">
                      <div className="home-header">
                        <img
                          alt="ImgSrc"
                          className="img-fluid"
                          src={value.thumbnail}
                        />
                      </div>
                      <div className="home-cnt-img">
                        <h6>{t(value.description)}</h6>
                        <div className="home-timer">
                          <div className="home-countdown-1">
                            <h6>00</h6>
                          </div>
                          <div className="dots">:</div>
                          <div className="home-countdown-1">
                            <h6>01</h6>
                          </div>
                          <div className="dots">:</div>
                          <div className="home-countdown-1">
                            <h6>18</h6>
                          </div>
                          <div className="dots">:</div>
                          <div className="home-countdown-1">
                            <h6>23</h6>
                          </div>
                        </div>
                        <div className="home-btn-enter">
                          <button>
                            {t("Enter")}{" "}
                            <i className="fa-solid fa-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
  
            <div className="home-all-1 px-20">
              <div className="home-main-sec-1 py-4">
                <div className="home-sec-1 section_title">
                  <div className="home-headings-1">
                    <h5>{t("Top Topics")}</h5>
                  </div>
                  <div className="home-see-all">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        handleSeeAll("topTopic");
                      }}
                    >
                      {" "}
                      {t("See all")} <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
  
                <div className="home-sec-3 row">
                  {topTopic.length > 0 &&
                    topTopic.slice(0, 2).map((value, index) => {
                      return (
                        <div className="col-md-6 my-2" key={index}>
                          <button
                            key={index}
                            className="button-handle"
                            onClick={() => {
                              handlePlay(value.id, topTopic);
                            }}
                          >
                            <div className="home-category">
                              <div className="home-cate-img-1">
                                <img
                                  alt="ImgSrc"
                                  className="img-fluid"
                                  src={value.thumbnail}
                                />
                              </div>
                              <div className="home-cate-cnt-1">
                                <h5>{t(value.name)}</h5>
                                <p>
                                  {t(value.description)} / {value.plays}{" "}
                                  {t("plays")}
                                </p>
                              </div>
                            </div>
                          </button>
                        </div>
                      );
                    })}
                </div>
              </div>
  
              <div className="home-main-sec-2 py-4">
                <div className="home-sec-1 section_title">
                  <div className="home-headings-1">
                    <h5>{t("Latest Topics")}</h5>
                  </div>
                  <div className="home-see-all">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        handleSeeAll("latestTopic");
                      }}
                    >
                      {" "}
                      {t("See all")} <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
  
                <div className="home-sec-3 row">
                  {latestTopic.length > 0 &&
                    latestTopic.slice(0, 2).map((value, index) => {
                      return (
                        <div className="col-md-6 my-2" key={index}>
                          <button
                            className="button-handle"
                            key={index}
                            onClick={() => {
                              handlePlay(value.id, latestTopic);
                            }}
                          >
                            <div className="home-category">
                              <div className="home-cate-img-1">
                                <img
                                  alt="ImgSrc"
                                  className="img-fluid"
                                  src={value.thumbnail}
                                />
                              </div>
                              <div className="home-cate-cnt-1">
                                <h5>{t(value.name)}</h5>
                                <p>
                                  {t(value.description)} / {value.plays}{" "}
                                  {t("plays")}
                                </p>
                              </div>
                            </div>
                          </button>
                        </div>
                      );
                    })}
                </div>
              </div>
  
              <div className="home-main-sec-4 py-4">
                <div className="home-sec-1 section_title">
                  <div className="home-headings-1">
                    <h5>{t("Favourite Topics")}</h5>
                  </div>
                  <div className="home-see-all">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        handleSeeAll("favouriteTopic");
                      }}
                    >
                      {" "}
                      {t("See all")} <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
  
                <div className="home-sec-3 row">
                  {userFavourite.length > 0 &&
                    userFavourite.slice(0, 2).map((value, index) => {
                      return (
                        <div className="col-md-6 my-2" key={index}>
                          <button
                            className="button-handle"
                            key={index}
                            onClick={() => {
                              handlePlay(value.id, userFavourite);
                            }}
                          >
                            <div className="home-category">
                              <div className="home-cate-img-1">
                                <img
                                  alt="ImgSrc"
                                  className="img-fluid"
                                  src={value.thumbnail}
                                />
                              </div>
                              <div className="home-cate-cnt-1">
                                <h5>{t(value.name)}</h5>
                                <p>
                                  {t(value.description)} / {value.plays}{" "}
                                  {t("plays")}
                                </p>
                              </div>
                            </div>
                          </button>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <Footer/>
            {/* <Footer data={topTopic} /> */}
          </div>
        </section>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>
    );
  }
};
export default Home;