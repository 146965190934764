let players={
    "players": [
        {
            "score": "90",
            "image": "https://www.gameninja.in/html/Azmoon/avatar.png",
            "game": "History",
            "service": "Ser102",
            "name": "John",
            "position": "1",
            "ani": "9805702978"
        },
        {
            "score": "50",
            "image": "https://www.gameninja.in/html/Azmoon/avatar.png",
            "game": "History",
            "service": "Ser102",
            "name": "Jack",
            "position": "2",
            "ani": "9823489193"
        },
        {
            "score": "30",
            "image": "https://www.gameninja.in/html/Azmoon/avatar.png",
            "game": "History",
            "service": "Ser102",
            "name": "Harry",
            "position": "3",
            "ani": "928343134"
        }
    ]
}
export{players};

let quizData={
    "id": 121,
    "question": "How many times  Ahmed Shah Abdali attacked to India?",
    "optionA": "Five times",
    "optionB": "Seven times",
    "optionC": null,
    "optionD": null,
    "correct": "2",
    "language": "English",
    "category": "History",
    "type": "Easy",
    "status": "0",
    "service": "Ser102",
    "correctAnswer": null
}
export{quizData};

let questionLogData={
    "id": 1,
    "ani": "9805702978",
    "question": "What is the name of the oldest civilization in the world?",
    "optionA": "The Sumerians",
    "optionB": "The Kushans",
    "optionC": null,
    "optionD": null,
    "correctAnswer": "The Sumerians",
    "category": "History",
    "language": "English",
    "service": "Ser102",
    "status": "Sent",
    "sendDateTime": "2022-11-09T16:05:26.696952"
};
export{questionLogData};