import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/profile.css";
// import unnamed_image from "../Images/unnamed-removebg-preview.png";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const ProfilePage = () => {
  const { t } = useTranslation();

  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

useEffect(()=>{
  handleChangeLang();
},[]);

  //To Navigate to any other page
  const navigate = useNavigate();

  useEffect(() => {
    getToken();
    getLocalStorageData();
  }, []);

  //States to hold data
  const [userFavourite, setUserFavourite] = useState([]);
  const[userName,setUserName]=useState('');
  const[userImage,setUserImage]=useState('');

  // const[topTopic,setTopTopic]=useState([]);

  //Getting Data From LocalStorage
  const getLocalStorageData = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    //console.log("user is ", user);
    setUserFavourite(user.favourite);
    setUserName(user.name);
    setUserImage(user.image);

    // let topTopic=JSON.parse(localStorage.getItem("topTopic"));
    // setTopTopic(topTopic);
  };

  //Method to handle when user click on any game
  const handlePlay=(id)=>{
    //console.log("id is ",id);
    userFavourite.forEach(e=>{
      if(id===e.id)
      {
        //console.log(e);
        localStorage.setItem("game", JSON.stringify(e));
        setCategoryAndService(e.name, e.service);
        navigate("/game");
      }
    })
  }

  const setCategoryAndService = (cat, ser) => {
    localStorage.setItem("category", cat);
    localStorage.setItem("service", ser);
  }

  // const handleDefaultPlay=()=>{
  //   // //console.log("Inside default Play");
  //   let id=1;
  //   // //console.log("id is ", id);
  //   //Now get this id data from array i.e in hook
  //   topTopic.forEach((value) => {
  //     if (id === value.id) {
  //       // //console.log("value ",value);
  //       localStorage.setItem("game", JSON.stringify(value));
  //       setCategoryAndService(value.name, value.service);
  //       navigate("/game");
  //       // let game=JSON.parse(localStorage.getItem("game"));
  //       // //console.log("game is ",game);
  //     }
  //   })
  // }

  //Method to Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

    if(token===undefined||token===null||token===''||token===' ')
    {
    return(
        <>
          <SelectLanguage/>
        </>
      );
    }
    else
    {
      return (
        <div>
          <section className="profile-main full-height">
            <div className="profile-main-2 flex-grow-1">
              <div className="rewards-navbar ">
                <div className="rewards-nav-1">
                  <button onClick={() => {
                      navigate("/home");
                    }}>
                    <span>
                      <i className="fa-solid fa-arrow-left"></i>
                    </span>
                  </button>
                  <div className="reward_title">
                    <span className="rewards-para-1">
                      <button><h5>{t('Profile')}</h5></button>
                    </span>
                  </div>
                </div>
                <div className="profile-power">
                  <span>
                    <i className="fa-sharp fa-solid fa-power-off"></i>
                  </span>
                </div>
              </div>
              <div className="profile-sidebar">
                <div className="profile-full-prfl-cnt">
                  <div className="profile-profile">
                    <img alt="ImgSrc" src={userImage} />
                  </div>
                  <div className="profile-prfl-data">
                    <h5>{userName}</h5>
                    <p>{t('Level')} 2</p>
                  </div>
                  <div className="profile-prfl-btn">
                    <button
                      onClick={() => {
                        navigate("/editprofile");
                      }}
                    >
                      <i className="fa-sharp fa-solid fa-pencil"></i>  {t('Edit Profile')}
                    </button>
                  </div>
                </div>
              </div>
              {/* <!-- Favourites topics  --> */}
              <div className="profile-body-cnt">
                <div className="home-sec-1 section_title">
                  <div className="home-headings-1">
                    <h5>{t('Favourite Topics')}</h5>
                  </div>
                </div>
                <div className="profile-topics-cnt row">
                  {userFavourite.length > 0 &&
                    userFavourite.map((value, index) => {
                      // //console.log("index  ", index);
                      // //console.log("value ", value);
    
                      return (
                      <div className="col-md-6 my-2" key={index}>
                        <button className="button-handle" key={index} onClick={()=>{
                          handlePlay(value.id);
                        }}>
                          <div
                            className="home-category"
                          >
                            <div className="home-cate-img-1">
                              <img alt="ImgSrc" src={value.thumbnail} />
                            </div>
                            <div className="home-cate-cnt-1">
                              <h5>{t(value.name)}</h5>
                              <p>
                                {t(value.description)} / {value.plays}{t('plays')}
                              </p>
                            </div>
                          </div>
                        </button>
                      </div>
                      );
                    })}
    
                  {/* <button>
                    <div className="profile-category">
                      <div className="profile-cate-img-1">
                        <img alt="ImgSrc" src={friend_image} />
                      </div>
                      <div className="profile-cate-cnt-1">
                        <h5>Friends</h5>
                        <p>Movies And Series / 565 plays</p>
                      </div>
                    </div>
                  </button> */}
                  {/* <button>
                    <div className="profile-category">
                      <div className="profile-cate-img-1">
                        <img alt="ImgSrc" src={friend_image} />
                      </div>
                      <div className="profile-cate-cnt-1">
                        <h5>Friends</h5>
                        <p>Movies And Series / 565 plays</p>
                      </div>
                    </div>
                  </button> */}
                </div>
              </div>
    
            </div>
            <Footer />
          </section>
        </div>
      );    
    }
  };
export default ProfilePage;