import React from "react";
import menu_bar_3_removebg_preview from "../Images/menu-bar-3-removebg-preview.png";
import Azmoon_logo from "../Images/Azmoon logo.png";
import { Link, useNavigate } from "react-router-dom";

export const NavbarTop = () => {
  //To Navigate
  const navigate = useNavigate();

  //To send to Top Players Page
  const handleTopPlayers = () => {
    navigate("/top");
  };

  const showSidebar = () => {
    
      let d = document.getElementById("left-sidebar-main");
      d.classList.add("my-show");
    
  };

  return (
    <>
      <div className="home-nav">
        <div className="home-menu-bar">
          <button
            onClick={() => {
              showSidebar();
            }}
          >
            <img
              alt="ImgSrc"
              className="img-fluid"
              src={menu_bar_3_removebg_preview}
            />
          </button>
        </div>
        <div className="home-logo-1">
          <Link to="/">
            <img alt="ImgSrc" className="img-fluid" src={Azmoon_logo} />
          </Link>
        </div>
        <button
          onClick={() => {
            handleTopPlayers();
          }}
        >
          <div className="home-win-sign-1">
            <i className="fa-sharp fa-solid fa-2xl fa-ranking-star"></i>
          </div>
        </button>
      </div>
    </>
  );
};