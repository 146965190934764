import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import img_123 from "../Images/123 img.png";
import play from "../Images/play.png";
import "../Css/game.css";
import { sendScorers,addToFavourite,deleteFromFavourite,saveGamePlaysApi } from "../Api/api";
import { players } from "../Data/data";
import { sendUser } from "../Api/api";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import SelectLanguage from "./1SelectLanguage";
import PostRequest from "../Api/PostRequest";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const Game = () => {

      //To Translate Language
      const { t } = useTranslation();

      //To Get Selected Language from Local Storage
      const handleChangeLang=()=>{
        let language=localStorage.getItem("language");
        i18n.changeLanguage(language);
    }

    //To Set Language on Page Load
    useEffect(()=>{
      handleChangeLang();
    },[]);

    //To go on any other page
    const navigate=useNavigate();

    //To get data from local storage
    useEffect(()=>{
        getGameFromLocalStorage();
        // eslint-disable-next-line
    },[]);

    //hook to store game data
    const[name,setName]=useState('');
    const[description,setDescription]=useState('');
    const[serviceName,setServiceName]=useState('');
    const[plays,setPlays]=useState('');
    const[questions,setQuestions]=useState('');
    const[thumbnail,setThumbnail]=useState('');

    //Method to get Game data from Local Storage
    const getGameFromLocalStorage=()=>{
        let game=JSON.parse(localStorage.getItem("game"));
        // //console.log("game is ",game);
        setName(game.name);
        setDescription(game.description);
        // setInfo(game.info);
        setPlays(game.plays);
        setQuestions(game.questions);
        setThumbnail(game.thumbnail);

        setServiceName(game.service);
        getScorers(game.service);
    }

    //Find Scorers using service
    const getScorers=(service)=>{
        //console.log("service is ",service);
        hitOnApi(service,"all");
    }

    //Hooks to store three users score
    const[first,setFirst]=useState({});
    const[second,setSecond]=useState({});
    const[third,setThird]=useState({});

    useEffect(()=>{
    },[first],[second],[third]);

    //Get Top players all/daily/weekly/monthly
    const getTopPlayers=(type)=>{

      //Adding Active class according to selected button
      //console.log("type is ",type);
      if(type==='all')
      {
        setAll('active');setDaily('');setWeekly('');setMonthly(''); 
      }
      else if(type==='daily')
      {
        setAll('');setDaily('active');setWeekly('');setMonthly(''); 
      }
      else if(type==='weekly')
      {
        setAll('');setDaily('');setWeekly('active');setMonthly(''); 
      }
      else if(type==='monthly')
      {
        setAll('');setDaily('');setWeekly('');setMonthly('active'); 
      }

      hitOnApi(serviceName,type);
    }

    //Hit On Api to get scorers
    const hitOnApi=(service,type)=>{

      let token=localStorage.getItem("token");
      let headers={"Authorization":"Bearer "+token};  

        let request={"service":service,"type":type}
        axios.post(`${sendScorers}`,request,{headers:headers})
        .then(
            (response)=>{
                //console.log(response.data);

                if(response.data.players.length===0)
                {
                  //console.log("No Data Found - So Set Default Dat");
                  setFirst(players.players[0]);
                  setSecond(players.players[1]);
                  setThird(players.players[2]);
                }
                else if(response.data.players.length===1)
                {
                  //console.log("length is 1");
                  setFirst(response.data.players[0]);
                  setSecond(players.players[1]);
                  setThird(players.players[2]);  
                }
                else if(response.data.players.length===2)
                {
                  //console.log("length is 2");

                  setFirst(response.data.players[0]);
                  setSecond(response.data.players[1]);
                  setThird(players.players[2]);  
                }
                else
                {
                  setFirst(response.data.players[0]);
                  setSecond(response.data.players[1]);
                  setThird(response.data.players[2]);
                }
            },
            (error)=>{
                //console.log(error);
                if(error.response.status===403)
                {
                  //console.log("Token Expired");
                  toast.error("Token Expired Plz Login again !!");
                  navigate("/");
                }
                else
                {
                  toast.error("Something Went Wrong");
                }
            }
        )
    }

    //Hook to store button text
    const[favouriteType,setFavouriteType]=useState('Add To Favourite');

    useEffect(()=>{
      getLocalStorageData();
      getToken();
      // eslint-disable-next-line
    },[])

    // const[userFavourite,setUserFavourite]=useState([]);

    //Getting Data From LocalStorage
    const getLocalStorageData=()=>{
      let user=JSON.parse(localStorage.getItem("user"));
      //console.log("user is ",user.favourite);
      // setUserFavourite(user.favourite);

      if(user.favourite.length===0)
      {
        setFavouriteType("Add To Favourite");
      }

      let service=localStorage.getItem("service");
      //console.log("service is ",service);

      checkingFavourite(user.favourite,service);
    }

    //Checking Favourite
    const checkingFavourite=(user,service)=>{
      // //console.log("inside");
      // //console.log(user);

      for(let i=0;i<user.length;i++)
      {
        //console.log("user ",user);
        //console.log("user[i] ",user[i]);
        if(user[i].service.trim()===service.trim())
        {
          //console.log("Already Favourite");
          setFavouriteType("Remove From Favourite");
          break;
        }
        else if(user[i].service.trim()!==service.trim())
        {
          //console.log("Not Already Favourite");
          setFavouriteType("Add To Favourite");
        }
      }
    }

    //Handle add to Favourite
    const handleAddToFavourite=()=>{
      //console.log("Handle Operation");
      //console.log("current is ",favouriteType);

      if(favouriteType==="Remove From Favourite")
      {
        //Hit for Remove
        let url=deleteFromFavourite;
        hitOnBackendToChange(url);
      }
      else if(favouriteType==="Add To Favourite")
      {
        //Hit for Add
        let url=addToFavourite;
        hitOnBackendToChange(url);
      }
    }

    //hit to backend
    const hitOnBackendToChange=(url)=>{

      let category=localStorage.getItem("category");
      let service=localStorage.getItem("service");
      let ani=localStorage.getItem("ani");
      let language=localStorage.getItem("language");

      let token=localStorage.getItem("token");
      let headers={"Authorization":"Bearer "+token};

      let request={"category":category,"service":service,"ani":ani,"language":language};
      axios.post(`${url}`,request,{headers:headers})
      .then(
        (response)=>{
          //console.log(response.data);
          getUserData();
        },
        (error)=>{
          //console.log(error);
          if(error.response.status===403)
          {
            //console.log("Token Expired");
            toast.error("Token Expired Plz Login again !!");
            navigate("/");
          }
          else
          {
            toast.error("Something Went Wrong");
          }
        }
      )
    }

  //Method to get Logged in user data
  const getUserData = () => {

    let token=localStorage.getItem("token");
    let headers={"Authorization":"Bearer "+token};

    let ani=localStorage.getItem("ani");
    let request = { "ani": ani };
    axios.post(`${sendUser}`, request,{headers:headers})
      .then(
        (response) => {
          //console.log(response.data);
          //Adding User to LocalStorage
          localStorage.setItem("user", JSON.stringify(response.data.user));

          // let getUser=JSON.parse(localStorage.getItem("user"));
          // //console.log("getUser ",getUser);
          getLocalStorageData();
        },
        (error) => {
          //console.log(error);
          if(error.response.status===403)
          {
            //console.log("Token Expired");
            toast.error("Token Expired Plz Login again !!");
            navigate("/");
          }
          else
          {
            toast.error("Something Went Wrong");
          }
        }
      )
  }
  const sendToHome = () => {
    navigate("/home");
  };

  //Hooks to add active class
  const[all,setAll]=useState("active");
  const[daily,setDaily]=useState("");
  const[weekly,setWeekly]=useState("");
  const[monthly,setMonthly]=useState("");

  const handlePlayButton=()=>{
      //Getting Selected Game
      let game=JSON.parse(localStorage.getItem("game"));
      // console.log("Game is ",game);

      let request={"service":game.service};
      //Saving Count
      let promise=PostRequest(saveGamePlaysApi,request)
      promise.then(e=>{
        if(e.response==='5')
        {
          navigate("/error");
        }
        else if(e.response==='6')
        {
          toast.success("Token Expired");
          navigate("/");
        }
        else
        {
          // console.log("response ",e);
        }
      })

      //Sending To Game
      navigate("/play");
  }

  //Method to Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

  if(token===undefined||token===null||token===''||token===' ')
  {
    return(
      <>
        <SelectLanguage/>
      </>
    );
  }
  else
  {
    return (
      <>
        <ToastContainer/>
        <section className="main full-height">
          <div className="main-2 flex-grow-1">
            <div className="rewards-navbar ">
              <div className="rewards-nav-1">
                <button 
                  onClick={() => {
                    sendToHome();
                  }}>
                  <span>
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                </button>
                <div className="reward_title">
                  <span className="rewards-para-1">
                    <button><h5>{t(name)}</h5></button>
                  </span>
                </div>
              </div>
            </div>
            <div className="gameheader">
              <div className="head-img">
                <img alt="ImgSrc"  src={`${thumbnail}`} />
              </div>
  
              <div className="head-cnt">
                <h5>{t(name)}</h5>
                <p>{t(description)}</p>
                <p className="para-1">
                  <span>
                    {" "}
                    <i className="fa-solid fa-play"></i> {plays} {t('plays')}
                  </span>{" "}
                  <span>
                    <i className="fa-solid fa-circle-question"></i>{questions}
                  </span>
                </p>
                <div className="btn-1">
                  <button onClick={()=>{
                    handleAddToFavourite();
                  }}>{t(favouriteType)}</button>
                </div>
              </div>
            </div>
            <section className="game_leader section-1">
              <h5>{t('Topic leaderboard')}</h5>
              <div className="ctgry">
                
                <button className={all} onClick={()=>{
                  getTopPlayers("all");
                }}><div className="btn-2">{t('All')}</div></button>
                
                <button className={daily} onClick={()=>{
                  getTopPlayers("daily");
                }}><div className="btn-2">{t('Daily')}</div></button>
                
                <button className={weekly} onClick={()=>{
                  getTopPlayers("weekly");
                }}><div className="btn-2">{t('Weekly')}</div></button>
                
                <button className={monthly} onClick={()=>{
                  getTopPlayers("monthly");
                }}><div className="btn-2">{t('Monthly')}</div></button>
              </div>
  
              <p className="para-2">{t('Your position')}: </p>
              <section className="sec-2">
                <div className="sec-2-cnt prfl-1">
                  <div className="raees">
                    <img alt="ImgSrc"  src={first.image} />
                  </div>
                  <div className="NAME-1">
                    <h5>{first.name}</h5>
                  </div>
                  <div className="para">
                    <p>{first.score} {t("score")}</p>
                  </div>
                </div>
                <div className="sec-2-cnt">
                  <div className="raees">
                    <img alt="ImgSrc"  src={second.image} />
                  </div>
                  <div className="NAME-1">
                    <h5>{second.name}</h5>
                  </div>
                  <div className="para">
                    <p>{second.score}{t("score")}</p>
                  </div>
                </div>
                <div className="sec-2-cnt prfl-2">
                  <div className="raees">
                    <img alt="ImgSrc"  src={third.image} />
                  </div>
                  <div className="NAME-1">
                    <h5>{third.name}</h5>
                  </div>
                  <div className="para">
                    <p>{third.score}{t("score")}</p>
                  </div>
                </div>
              </section>
              <div className="position-img">
                <img alt="ImgSrc"  src={img_123} />
              </div>
              <div className="footer">
                <button onClick={()=>{
                  handlePlayButton();
                }}>
                  <img alt="ImgSrc"  src={play} />
                </button>
              </div>
            </section>
          </div>
          <Footer image="New" />
        </section>
      </>
    );
  }
};
export default Game;