import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import unnamed_removebg_preview from "../Images/unnamed-removebg-preview.png";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import arrow from "../Images/arrow.png";

export const Footer = (props) => {
  //To Translate Language
  const { t } = useTranslation();

  //To Get Language from Local Storage
  const handleChangeLang = () => {
    let language = localStorage.getItem("language");
    i18n.changeLanguage(language);
  };

  //To Set Language on Load
  useEffect(() => {
    handleChangeLang();
    settingData();
    // eslint-disable-next-line
  }, []);

  //To Navigate
  const navigate = useNavigate();

  const setCategoryAndService = (cat, ser) => {
    localStorage.setItem("category", cat);
    localStorage.setItem("service", ser);
  };

  //Hook to store play Button Data
  const[mainData,setMainData]=useState([]);

  const settingData=()=>{
    // console.log("props.data is ",props.data);
    if(props.data===undefined || props.data==null || props.data.length===0)
    {
        let topTopic = JSON.parse(localStorage.getItem("topTopic"));
        // console.log("topTopic is ",topTopic);
        setMainData(topTopic);
    }
    else
    {
      setMainData(props.data);
    }

    //checking image
    if(props.image!==undefined)
    {
      setImage(`${arrow}`);
    }

    handleDefaultPlay();
  }

  const handleDefaultPlay = () => {
    let id = 1;
    //Now get this id data from array i.e in hook
    mainData.length>0 && mainData.forEach((value) => {
      if (id === value.id) {
        localStorage.setItem("game", JSON.stringify(value));
        setCategoryAndService(value.name, value.service);
        navigate("/game");
      }
    });
  };

  //Hook to store image
  const[image,setImage]=useState(`${unnamed_removebg_preview}`);

  return (
    <>
      <section className="home-footer">
        <div className="home-icon-1">
          <button
            onClick={() => {
              navigate("/home");
            }}
          >
            <i className="fa-solid fa-house-user"></i>
            <p>{t("Home")}</p>
          </button>
        </div>
        <div className="home-icon-1">
          <button
            onClick={() => {
              navigate("/league");
            }}
          >
            <i className="fa-solid fa-trophy"></i>
            <p>{t("League")}</p>
          </button>
        </div>
        <div className="home-icon-5">
          <button
            onClick={() => {
              handleDefaultPlay();
            }}
          >
            <img
              alt="ImgSrc"
              className="img-fluid"
              // src={unnamed_removebg_preview}
              src={image}
            />
            <p>{t("Play")}</p>
          </button>
        </div>
        <div className="home-icon-1">
          <button
            onClick={() => {
              navigate("/search");
            }}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
            <p>{t("Search")}</p>
          </button>
        </div>
        <div className="home-icon-1">
          <button
            onClick={() => {
              navigate("/profile");
            }}
          >
            <i className="fa-solid fa-user"></i>
            <p>{t("Profile")}</p>
          </button>
        </div>
      </section>
    </>
  );
};