import React from "react";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Setting from "../Pages/10Setting";
import ShowQuiz from "../Pages/11ShowQuiz";
import Rewards from "../Pages/12Rewards";
import Invite from "../Pages/13Invite";
import ProfilePage from "../Pages/14Profile";
import EditProfile from "../Pages/15EditProfile";
import Update from "../Pages/16Update";
import Search from "../Pages/17Search";
import Notification from "../Pages/18Notification";
import League from "../Pages/19League";
import SelectLanguage from "../Pages/1SelectLanguage";
import Login from "../Pages/2Login";
import EnterCode from "../Pages/3EnterCode";
import Subscribe from "../Pages/3Subscribe";
import Home from "../Pages/4Home";
import SeeAll from "../Pages/5SeeAll";
import Game from "../Pages/6Game";
import Play from "../Pages/7Play";
import TopPlayers from "../Pages/8TopPlayers";
import ShowScore from "../Pages/9ShowScore";
import ErrorPage from "../Pages/ErrorPage";
import PageNotFound from "../Pages/PageNotFound";

const Routing=()=>{
 return(
    <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SelectLanguage/>} exact={true}></Route>
                <Route path="/login" element={<Login/>} exact={true}></Route>
                <Route path="/otp" element={<EnterCode/>} exact={true}></Route>
                <Route path="/subscribe" element={<Subscribe/>} exact={true}></Route>
                <Route path="/home" element={<Home/>} exact={true}></Route>
                <Route path="/all" element={<SeeAll/>} exact={true}></Route>
                <Route path="/game" element={<Game/>} exact={true}></Route>
                <Route path="/play" element={<Play/>} exact={true}></Route>
                <Route path="/top" element={<TopPlayers/>} exact={true}></Route>
                <Route path="/score" element={<ShowScore/>} exact={true}></Route>
                <Route path="/setting" element={<Setting/>} exact={true}></Route>
                <Route path="/show" element={<ShowQuiz/>} exact={true}></Route>
                <Route path="/reward" element={<Rewards/>} exact={true}></Route>
                <Route path="/invite" element={<Invite/>} exact={true}></Route>
                <Route path="/profile" element={<ProfilePage/>} exact={true}></Route>
                <Route path="/editprofile" element={<EditProfile/>} exact={true}></Route>
                <Route path="/update" element={<Update/>} exact={true}></Route>
                <Route path="/search" element={<Search/>} exact={true}></Route>
                <Route path="/notification" element={<Notification/>} exact={true}></Route>
                <Route path="/league" element={<League/>} exact={true}></Route>
                <Route path="*" element={<PageNotFound/>} exact={true}></Route>
                <Route path="/error" element={<ErrorPage/>} exact={true}></Route>
            </Routes>
        </BrowserRouter>
    </>
 );   
}
export default Routing;