import React,{useEffect} from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import invite_image from "../Images/invite.jpg";
import "../Css/invite.css";
import tEn from "../Translation/en.json";
import tDr from "../Translation/dr.json";
import tPs from "../Translation/ps.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Footer } from "./Footer";
import SelectLanguage from "./1SelectLanguage";

i18n
    .use(initReactI18next) 
    .init({
      resources: {
        en: {translation: tEn},
        ps: {translation: tPs},
        dr: {translation: tDr}
      },
      // lng:"ps", 
      // fallbackLng: "en",
  
      // interpolation: {
      //   escapeValue: false 
      // }
    });

let token;
const Invite = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleChangeLang=()=>{
    let language=localStorage.getItem("language");
    i18n.changeLanguage(language);
}

useEffect(()=>{
  handleChangeLang();
  getToken();
},[]);

  //Method to Get & Check Token
  const getToken=()=>{
    token=localStorage.getItem("token");
  }

  if(token===undefined||token===null||token===''||token===' ')
  {
	return(
      <>
        <SelectLanguage/>
      </>
    );
  }
  else
  {
    return (
      <>
        <section className="invite-main full-height">
          <div className="invite-main-2 flex-grow-1">
            <div className="rewards-navbar ">
              <div className="rewards-nav-1">
                <button onClick={() => {
                    navigate("/home");
                  }}>
                  <span>
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>
                </button>
                <div className="reward_title">
                  <span className="rewards-para-1">
                    <button><h5>{t('Invite friends')}</h5></button>
                  </span>
                </div>
              </div>
            </div>
          
            <div className="invite-body">
              <div className="invite-share">
                <h5>{t('Share with friends')}</h5>
                <p>{t('Invite your friends to join us')}</p>
              </div>
              <div className="invite-invite-img">
                <img alt="ImgSrc" src={invite_image} />
              </div>
              <div className="invite-copy-btn">
                <CopyToClipboard
                  text="http://azmoon.thehappytubes.com/"
                  onCopy={() => alert("Copied")}
                >
                  <button>
                    {t('Copy link')} <i className="fa-solid fa-link"></i>
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    );  
  }
};
export default Invite;